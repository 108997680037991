.betaBanner {
  background: linear-gradient(135deg, #7156f5 0%, #2563eb 100%);
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  animation: slideDown 0.3s ease-out;
  gap: 32px; }
  .betaBanner .content {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0; }
    .betaBanner .content h3, .betaBanner .content p {
      margin: 0;
      font-size: 14px; }
    .betaBanner .content h3 {
      font-weight: 500;
      color: white; }
    .betaBanner .content p {
      color: rgba(255, 255, 255, 0.9); }
  .betaBanner .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0; }
  .betaBanner .tryButton {
    background: rgba(255, 255, 255, 0.15);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 6px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s; }
    .betaBanner .tryButton:hover {
      background: rgba(255, 255, 255, 0.25);
      transform: translateY(-1px); }
    .betaBanner .tryButton[disabled] {
      opacity: 0.7;
      cursor: not-allowed; }
  .betaBanner .dismissButton {
    color: rgba(255, 255, 255, 0.8);
    background: none;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s; }
    .betaBanner .dismissButton:hover {
      color: white; }

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.betaBanner.dismissing {
  animation: slideUp 0.2s ease-out forwards; }

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1; }
  to {
    transform: translateY(-100%);
    opacity: 0; } }
